<script setup lang="ts">
import {
  useLeaseSectionTabs,
  useFastApproval,
  useLeaseNavigation,
} from './composables'

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import {
  useApproveFields,
  useMobileViewStore,
  useLeaseStore,
  useReviewSidebarSearch,
  useReviewSidebarSections,
  MobileView,
} from '@register'
import type { ITheLeaseSidebarAction } from '~/components/layout/TheLeaseSidebar'

const { lease, sections, getSection } = useCurrentLeaseDocument()

// SIDEBAR
const sidebar = useReviewSidebarSections()
const search = ref('')
const { results } = useReviewSidebarSearch(search, sidebar, {
  fuseOptions: {
    keys: [
      'items.title',
      'items.description',
      'items.id',
      'items.fields.title',
      'items.fields.description',
      'items.fields.id',
    ],
  },
})

// Tabs
const {
  active: activeTab,
  isActive: isActiveTab,
  change: changeTab,
} = useLeaseSectionTabs({ scrollOnMount: true })

const { nextPage, previousPage, exclusiveOpenGroup } = useLeaseNavigation(
  results,
  {
    routeId: ({ query }) => query.section as string,
  },
)

// Fast Approval
const { fastApproval } = useFastApproval()
// Only enable fast approved based on the active tab on page load
fastApproval.value = activeTab.value === 'FastApproval'
// Then, we watch the fast approval toggle and change the tab based on it.
watch(fastApproval, () => {
  if (fastApproval.value) {
    changeTab('FastApproval')
  } else {
    changeTab(sections.value[0].id)
  }
})

const { showProgress } = storeToRefs(useLeaseStore())

const { isAllApproved, submitHandler: onApproveAll } = useApproveFields()

const sidebarBus = useEventBus<string>('open:sidebarSection')
const isAllSidebarExpanded = ref(true)

const sidebarActions = computed<ITheLeaseSidebarAction[]>(() => [
  {
    title: `${showProgress.value ? 'Hide' : 'Show'} progress`,
    icon: 'checkmark-list',
    click: () => {
      showProgress.value = !showProgress.value
    },
  },
  {
    title: isAllSidebarExpanded.value
      ? 'Collapse all groups'
      : 'Expand all groups',
    icon: 'collapse',
    click: () => {
      if (isAllSidebarExpanded.value) {
        isAllSidebarExpanded.value = false
        sidebarBus.emit('close-all-groups')
      } else {
        isAllSidebarExpanded.value = true
        sidebarBus.emit('open-all-groups')
      }
    },
  },
  {
    title: 'Mandatory fields only',
    icon: 'asterisk',
    click: () => {
      fastApproval.value = !fastApproval.value
    },
  },
  {
    title: `${isAllApproved.value ? 'Unapprove' : 'Approve'} all fields`,
    icon: 'approve-all',
    click: onApproveAll,
  },
])

// PANELS
const { mobileView, isGroupsMobileView, isFieldsMobileView } =
  storeToRefs(useMobileViewStore())
const showGroupsPane = computed(
  () => !mobileView.value || isGroupsMobileView.value,
)
const showFieldsPane = computed(
  () => !mobileView.value || isFieldsMobileView.value,
)
</script>

<template>
  <Splitpanes v-if="lease" class="default-theme" :push-other-panes="true">
    <Pane
      v-if="showGroupsPane"
      class="relative"
      :max-size="mobileView ? 100 : 30"
      :min="mobileView ? 100 : 0.2"
      :size="mobileView ? 100 : 20"
    >
      <TheLeaseSidebar
        v-model:search="search"
        :sections="results"
        :actions="sidebarActions"
        :full-height="lease?.isPublished"
        @click="changeTab($event)"
      >
        <template v-if="fastApproval" #header>
          <LeaseReviewFastApprovalToggle />
        </template>

        <template #section-title="{ section: sidebarSection }">
          <h3
            class="text-gray-350 mr-2 w-full select-none self-end truncate text-xs font-bold uppercase"
          >
            {{ sidebarSection.title }}
          </h3>

          <div
            v-if="showProgress && sidebarSection.fields"
            class="mr-2 w-10 flex-none cursor-help"
          >
            <LeaseReviewFieldsProgress :fields="sidebarSection.fields" />
          </div>
        </template>

        <template #item-suffix="{ item: sidebarItem }">
          <span
            v-if="sidebarItem.mandatory"
            class="ml-1 mt-1 text-left text-gray-400"
          >
            <Tooltip
              placement="top"
              content="Contains mandatory fields"
              class="bg-gray-950 text-xs"
            >
              <Icon name="required" filled class="" />
            </Tooltip>
          </span>

          <div
            v-if="sidebarItem.fields && showProgress"
            class="flex flex-auto items-end justify-end opacity-40"
          >
            <div class="flex w-5">
              <LeaseReviewFieldsProgress :fields="sidebarItem.fields" />
            </div>
          </div>
        </template>

        <template v-if="fastApproval">
          <div class="ml-2 flex justify-center self-center pr-2 pt-4">
            <div class="shrink rounded-lg bg-gray-700 p-8 text-center text-sm">
              Filtering only mandatory fields
            </div>
          </div>
        </template>
      </TheLeaseSidebar>
    </Pane>

    <Pane
      v-if="showFieldsPane"
      class="relative"
      :max-size="mobileView ? 100 : 99.8"
      :size="mobileView ? 100 : 70"
    >
      <div
        class="middle-pane absolute inset-0 z-50 flex flex-col overflow-y-auto scroll-smooth px-2 pb-60 pl-4"
        :class="{ 'mb-[70px]': !lease?.isPublished }"
      >
        <slot name="header" />

        <slot
          :key="activeTab"
          :active="activeTab"
          :section="getSection(activeTab)"
        />
        <div>
          <hr class="mt-2 h-px border-t-0 border-dotted bg-gray-800" />

          <section class="mt-4 grid grid-cols-2">
            <div>
              <Button
                v-if="previousPage"
                class="bg-gray-750 hover:bg-primary group px-4 py-2 text-xs text-white hover:text-black"
                @click="
                  exclusiveOpenGroup(previousPage), changeTab(previousPage.id)
                "
              >
                <Icon name="chevron-left" filled />
                <span class="hidden group-hover:flex">
                  {{ previousPage.title }}
                </span>
              </Button>
            </div>
            <div class="flex justify-end">
              <Button
                v-if="nextPage"
                class="bg-gray-750 hover:bg-primary group px-4 py-2 text-xs text-white hover:text-black"
                @click="exclusiveOpenGroup(nextPage), changeTab(nextPage.id)"
              >
                <span class="hidden group-hover:flex">
                  {{ nextPage.title }}
                </span>
                <Icon name="chevron-right" filled />
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Pane>
  </Splitpanes>
</template>
